@import "variables";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.sidebar {
  background: $lightGray;
}

.cursor-pointer {
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.nav-link.active {
  font-weight: bold;
}

@import '~bootstrap/scss/bootstrap';

.btn-standard {
  border: $border-width solid var($border-color);
}

h2 {
  color: $mainColor;
}

.color-primary {
  color: $mainColor;
}

.bg-primaryLight {
  border: none;
  border-radius: 25px;
  background-color: lighten($mainColor, 50%);
}

.bg-primary-green {
  background-color: $primary-green;
}

.bg-whiteRounded {
  border: none;
  border-radius: 25px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  background-color: #fff !important;
}

.hideCaret .dropdown-toggle::after {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f6f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

$mainColor: #193B77;
$lightGray: rgb(248, 249, 250);
$danger: #d94234;
$primary-green: #3cb128;
$theme-colors: (
        "primary": $mainColor,
        "danger": $danger,
        "success": $primary-green,
        "secondary": #e8eceb
);

$card-cap-bg: $lightGray;
$table-striped-bg: $lightGray;
$accordion-bg: $lightGray;
$accordion-button-active-bg: $lightGray;
$link-color: $mainColor;
$navbar-light-active-color: $mainColor;
$nav-tabs-link-active-color: $mainColor;
$nav-link-hover-color: lighten($mainColor, 10%);
$nav-link-color: black;
$card-title-color: $mainColor;
$card-subtitle-color: darken($lightGray, 45%);
$tooltip-bg: rgba(0, 0, 0, 0.8);

$text-warning: rgb(197, 162, 47);
